import { llget, put, post, del, postUserId, putUserId, delUserId, get,patch } from "./base";

// OfficialWebsiteResearch页面和HelpCenterMenuManagement页面

// 查
export function getHelp(catalogue,state,pageNum,pageSize){
	return get(`/v3/helperType?catalogue=${catalogue}&state=${state}&pageNum=${pageNum}&pageSize=${pageSize}`)
}
// 增
export function postHelp(catalogue){
	return post(`/v3/helperType?catalogue=${catalogue}`) 
}
// 删除
export function delHelp(id){
	return del(`/v3/helperType?id=${id}`)
}
// 修改
export function putHelp(catalogue,id,state){
	return put(`/v3/helperType?catalogue=${catalogue}&id=${id}&state=${state}`)
}
// // 更新状态
// export function patchHelp(id,isOpen,byId){
// 	return patch(`/v3/yun/promote/updateCommunity?id=${id}&isOpen=${isOpen}&byId=${byId}`)
// }

export function gethelpsList(patientId,title,pageNum,pageSize){
	return get(`/v3/helper?patientId=${patientId}&title=${title}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

// 全部类型查询标题
export function getAllhelpsList(pageNum,pageSize){
	return get(`/v3/stateHelper?pageNum=${pageNum}&pageSize=${pageSize}`)
}

// ****官网调研***
// 查询
export function getOfficalSurvey(year,title,pageNum,pageSize,state){
	return get(`/v3/survey?year=${year}&title=${title}&pageNum=${pageNum}&pageSize=${pageSize}&state=${state}`)
}
// 状态开关
export function putOfficalSurvey(id,state){
	return put(`/v3/surveyState?id=${id}&state=${state}`)
}
// 上移
export function putUpSurvey(id){
	return put(`/v3/surveySortIndex/up?id=${id}`)
}
// 下移
export function putdownSurvey(id){
	return put(`/v3/surveySortIndex/down?id=${id}`)
}
// 新增
export function postSurvey(data){
	return post(`/v3/survey`,data) 
}
// 修改前查询数据  v3/surveyById?id=1
export function getSurveyDetails(id){
	return get(`/v3/surveyById?id=${id}`)
}
// 删除
export function delSurvey(id){
	return del(`/v3/survey?id=${id}`)
}

// 修改
export function putSurvey(data){
	return put(`/v3/survey`,data)
}
